/* This file contains styles used for both the preview and final PDF for document templates. */

/* CSS Reset */
p,
h1,
h3,
h4,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

:root {
  --brand-color: #225584;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
}

/* Automatic numbering */
.document-numbering-wrapper {
  counter-reset: item;
  margin: 0;
  padding: 40px 0;
  text-align: justify;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: var(--spacing-medium);
}

/* Numbering by header depth */

body {
  counter-reset: section;
}

.document-numbering-wrapper h2 {
  counter-reset: subsection;
}

.document-numbering-wrapper h3 {
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-small);
  counter-reset: subsubsection;
}

.document-numbering-wrapper h4 {
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-small);
  counter-reset: subsubsubsection;
}

.document-numbering-wrapper h5 {
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-small);
}

.document-numbering-wrapper h2::before {
  counter-increment: section;
  content: counter(section) '. ';
}

.document-numbering-wrapper h3::before {
  counter-increment: subsection;
  content: counter(section) '.' counter(subsection) ' ';
}

.document-numbering-wrapper h4::before {
  counter-increment: subsubsection;
  content: counter(section) '.' counter(subsection) '.' counter(subsubsection)
    ' ';
}

.document-numbering-wrapper h5::before {
  counter-increment: subsubsubsection;
  content: counter(section) '.' counter(subsection) '.' counter(subsubsection)
    '.' counter(subsubsubsection) ' ';
}

/* end numbering by header depth */

/* Table of contents page */
.toc {
  padding: 0;
  padding-left: var(--spacing-medium);
  margin: 0;
  font-weight: 400;
}

.toc a {
  color: black;
  text-decoration: none;
}

/* General list styling */
.document-numbering-wrapper ul {
  padding-left: var(--spacing-medium);
  margin: 0;
}

.document-numbering-wrapper li {
  padding: 4px 0;
}

/* Other styling */
.document-section {
  margin-bottom: var(--spacing-large);
}

.document-section section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-small);
}

.document-section table {
  border-collapse: collapse;
}

.document-section th,
.document-section td {
  border: 1px solid grey;
  padding: var(--spacing-small);
}

.document-section th {
  background-color: #f2f2f2;
}

.document-title-wrapper {
  color: var(--brand-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.heading-spacer {
  height: 5px;
  width: 280px;
  margin: 0;
  background-color: var(--brand-color);
}

.spacer {
  display: block;
  height: 2px;
  width: 100px;
  margin: var(--spacing-small) 0 var(--spacing-medium) 0;
  background-color: var(--brand-color);
}
